import './App.css';
import { useState } from 'react';
import AuthIllustrationWrapper from './AuthIllustrationWrapper';
import CustomTextField from './utils/ui/TextField'

//React Imports
import { Link } from "react-router-dom";

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import themeConfig from './utils/configs/themeConfig';

function Login() {
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const handleClickShowPassword = () => setIsPasswordShown(show => !show)

  return (
    <div className='flex flex-col justify-center items-center min-bs-[100dvh] p-6'>
        <p>test login</p>
        {/* <AuthIllustrationWrapper>
            <Card className='flex flex-col sm:is-[450px]'>
                <CardContent className='sm:!p-12'/>
                <a href={'/'} className='flex justify-center mbe-6'>
                    {'a'}
                </a>
                <div className='flex flex-col gap-1 mbe-6'>
                    <Typography variant='h4'>{`Welcome to ${themeConfig.templateName}! 👋🏻`}</Typography>
                    <Typography>Please sign-in to your account and start the adventure</Typography>
                </div>
            </Card>
        </AuthIllustrationWrapper> */}
        <AuthIllustrationWrapper>
            <Card className='flex flex-col sm:is-[450px]'>
                <CardContent className='sm:!p-12'>
                {/* <Link href={'/'} className='flex justify-center mbe-6'>
                    {/* <Logo /> 
                </Link> */}
                <div className='flex flex-col gap-1 mbe-6'>
                    <Typography variant='h4'>{`Welcome to ${themeConfig.templateName}! 👋🏻`}</Typography>
                    <Typography>Please sign-in to your account and start the adventure</Typography>
                </div>
                <form noValidate autoComplete='off' onSubmit={e => e.preventDefault()} className='flex flex-col gap-6'>
                    <CustomTextField autoFocus fullWidth label='Email or Username' placeholder='Enter your email or username' />
                    <CustomTextField
                    fullWidth
                    label='Password'
                    placeholder='············'
                    id='outlined-adornment-password'
                    type={isPasswordShown ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton edge='end' onClick={handleClickShowPassword} onMouseDown={e => e.preventDefault()}>
                            <i className={isPasswordShown ? 'tabler-eye-off' : 'tabler-eye'} />
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    />
                    <div className='flex justify-between items-center gap-x-3 gap-y-1 flex-wrap'>
                    <FormControlLabel control={<Checkbox />} label='Remember me' />
                    <Typography
                        className='text-end'
                        color='primary'
                        // component={Link}
                        href={'/new-structure/login-module/pages/test-forgot-password'}
                    >
                        Forgot password?
                    </Typography>
                    </div>
                    <Button 
                    fullWidth variant='contained' 
                    type='submit'
                    href={'/new-structure/dashboard-module/pages/dashboard'}>
                    Login
                    </Button>
                    <div className='flex justify-center items-center flex-wrap gap-2'>
                    <Typography>New on our platform?</Typography>
                    <Typography 
                        // component={Link} 
                        href={'https://workxcrm.com/registration'} 
                        color='primary' 
                        target='_blank'>
                        Create an account
                    </Typography>
                    </div>
                    <Divider className='gap-2 text-textPrimary'>or</Divider>
                    <div className='flex justify-center items-center gap-1.5'>
                    <IconButton className='text-facebook' size='small'>
                        <i className='tabler-brand-facebook-filled' />
                    </IconButton>
                    <IconButton className='text-twitter' size='small'>
                        <i className='tabler-brand-twitter-filled' />
                    </IconButton>
                    <IconButton className='text-textPrimary' size='small'>
                        <i className='tabler-brand-github-filled' />
                    </IconButton>
                    <IconButton className='text-error' size='small'>
                        <i className='tabler-brand-google-filled' />
                    </IconButton>
                    </div>
                </form>
                </CardContent>
            </Card>
        </AuthIllustrationWrapper>
    </div>
  );
}

export default Login;
